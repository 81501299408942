import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'app/main/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class SaldosInicialBancosService {

  constructor(
    public http: HttpClient,
    public global: GlobalService  ) {

  }

  getHeaders() {
    let headers = new HttpHeaders({
      'authorization': this.global.getToken()
    });
    return headers;
  }

  obterAnexosPorContaLancamento(contaId:number, lancamentoId:number) {
    return this.http.get<any>(this.global.url + `/saldos/obter-anexos/conta/${contaId}/lancamento/${lancamentoId}`, { headers: this.getHeaders() });
  }

  atualizarAnexoContaBancariaLancamento(contaId:number, lancamentoId:number, obj) {
    return this.http.post<any>(this.global.url + `/saldos/conta/${contaId}/lancamento/${lancamentoId}/anexos`, obj, { headers: this.getHeaders() });
  }
  
  uploadAnexo(obj:File) {
    let formData = new FormData();
    formData.append("file", obj);
    return this.http.post<any>(this.global.url + `/saldos/upload/anexos`, formData, { headers: this.getHeaders() }).toPromise();
  }

  getSaldos(obj) {
    return this.http.post<any>(this.global.url + '/saldos/getSaldos', obj, { headers: this.getHeaders() });
  };

  getSaldosAtuais(obj) {
    return this.http.post<any>(this.global.url + '/saldos/getSaldosAtuais', obj, { headers: this.getHeaders() });
  };

  addSaldoInicio(obj) {
    return this.http.post<any>(this.global.url + '/saldos/addSaldoInicio', obj, { headers: this.getHeaders() });
  };

  updateSaldoInicio(obj) {
    return this.http.post<any>(this.global.url + '/saldos/updateSaldoInicio', obj, { headers: this.getHeaders() });
  };

  delSaldoInicio(obj) {
    return this.http.post<any>(this.global.url + '/saldos/delSaldoInicio', obj, { headers: this.getHeaders() });
  };

  consulta(obj) {
    return this.http.post<any>(this.global.url + '/saldos/consulta', obj, { headers: this.getHeaders() });
  };
}
