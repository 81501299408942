import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'app/main/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class SaldosRubricasService {

  constructor(
    public http: HttpClient,
    public global: GlobalService  ) {

  }

  getHeaders() {
    let headers = new HttpHeaders({
      'authorization': this.global.getToken()
    });
    return headers;
  }

  getAnos(id) {
    let obj = {
      IDG004: id
    }
    return this.http.post<any>(this.global.url + '/saldosRubricas/getAnos', obj, { headers: this.getHeaders() });
  };

  consulta(obj) {
    return this.http.post<any>(this.global.url + '/saldosRubricas/consulta', obj, { headers: this.getHeaders() });
  };

  getReceitasRubricas(obj) {
    return this.http.post<any>(this.global.url + '/saldosRubricas/getReceitasRubricas', obj, { headers: this.getHeaders() });
  };

  getDespesasRubricas(obj) {
    return this.http.post<any>(this.global.url + '/saldosRubricas/getDespesasRubricas', obj, { headers: this.getHeaders() });
  };

  obterRubricas(acoes = null, distrito = null) {

    let obj = {
      acoes: acoes,
      distrito: (distrito) ? distrito.IDG003 : null
    }

    return this.http.post<any>(this.global.url + '/saldosRubricas/obter-rubricas', obj, { headers: this.getHeaders() });
  };

  getSaldos(acoes = null, distrito = null) {

    let obj = {
      acoes: acoes,
      distrito: (distrito) ? distrito.IDG003 : null
    }

    return this.http.post<any>(this.global.url + '/saldosRubricas/getSaldos', obj, { headers: this.getHeaders() });
  };

  getMovimentacoes(id, infoPage) {
    let obj = {
      IDG019: id,
      paginacao: infoPage
    }
    return this.http.post<any>(this.global.url + '/saldosRubricas/getMovimentacoes', obj, { headers: this.getHeaders() });
  };

  addMovimentacao(obj) {
    return this.http.post<any>(this.global.url + '/saldosRubricas/addMovimentacao', obj, { headers: this.getHeaders() });
  };

  updateMovimentacao(obj) {
    return this.http.post<any>(this.global.url + '/saldosRubricas/updateMovimentacao', obj, { headers: this.getHeaders() });
  };

  delMovimentacao(obj) {
    return this.http.post<any>(this.global.url + '/saldosRubricas/delMovimentacao', obj, { headers: this.getHeaders() });
  };
}
