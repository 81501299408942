import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'app/main/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class MembrosService {


  constructor(
    public http: HttpClient,
    public global: GlobalService  ) {

  }

  getHeaders() {
    let headers = new HttpHeaders({
      'authorization': this.global.getToken()
    });
    return headers;
  }

  getMembros(obj) {
    return this.http.post<any>(this.global.url + '/membros/getMembros', obj, { headers: this.getHeaders() });
  };

  obterTipoUsuario(IDG009: any) {
    return this.http.get<any>(this.global.url + `/membros/tipo/${IDG009}`, { headers: this.getHeaders() });
  }


}
