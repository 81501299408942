import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'app/main/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class RelatoriosFinanceirosService {

  constructor(
    public http: HttpClient,
    public global: GlobalService  ) {

  }

  getHeaders() {
    let headers = new HttpHeaders({
      'authorization': this.global.getToken()
    });
    return headers;
  }

  relatoriosMovimentacoes(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/movimentacoes', obj, { headers: this.getHeaders() });
  };

  relatoriosSaldoRubricas(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/saldo-rubricas/periodo', obj, { headers: this.getHeaders() });
  };


  relatoriosRemessa(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/remessasAnual', obj, { headers: this.getHeaders() });
  };

  relatoriosRemessaMensal(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/remessasMensal', obj, { headers: this.getHeaders() });
  };

  relatoriosCotaOrcamentaria(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/cotaOrcamentaria', obj, { headers: this.getHeaders() });
  };

  relatoriosArquivoBanco(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/arquivoRetornoBanco', obj, { headers: this.getHeaders() });
  };

  relatoriosArquivoBancoTotais(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/arquivoRetornoBancoTotais', obj, { headers: this.getHeaders() });
  };

  relatoriosDizimos(obj){
    return this.http.post<any>(this.global.url + '/relatorios/dizimos', obj, { headers: this.getHeaders() });
  }


  relatoriosResumo(obj){
    return this.http.post<any>(this.global.url + '/relatorios/resumo', obj, { headers: this.getHeaders() });
  }

  balancetesMensal(obj){
    return this.http.post<any>(this.global.url + '/relatorios/balancetesMensal', obj, { headers: this.getHeaders() });
  }

  balancetesMensalSintetico(obj){
    return this.http.post<any>(this.global.url + '/relatorios/balanceteMensalSintatico', obj, { headers: this.getHeaders() });
  }

  balancetesAnual(obj){
    return this.http.post<any>(this.global.url + '/relatorios/balancetesAnual', obj, { headers: this.getHeaders() });
  }

  getResponsaveis(obj){
    return this.http.post<any>(this.global.url + '/relatorios/responsaveis', obj, { headers: this.getHeaders() });
  }

  getPermissaoLocais(obj){
    return this.http.post<any>(this.global.url + '/locais/getLocaisByPermissao', obj, { headers: this.getHeaders() });
  }

  relatoriosBens(obj) {
    return this.http.post<any>(this.global.url + '/relatorios/bens', obj, { headers: this.getHeaders() });
  };

  bancos(local) {

    let obj = {
        local: local
    }

    return this.http.post<any>(this.global.url + '/relatorios/bancos', obj, { headers: this.getHeaders() });
  };

}
